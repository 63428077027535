.app-layout {
    .ant-layout-sider-children {
        @apply h-full flex flex-col items-center;
        border-right: 1px solid #eee;
        height: 100dvh !important;
        overflow: hidden;
        
        .ant-menu {
            @apply border-0;
        }

        .ant-btn.trigger {
            @apply mt-auto mb-4;
        }
    }
    .ant-layout-sider-trigger {
        display: none;
    }

    main {
        @apply m-6;
    }

    .app-sidebar {
        @apply bg-white;
        height: 100dvh;
        position: sticky;
        top: 0;
    }
}
