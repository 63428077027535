.individual {
    .statistics {
        @apply min-h-[84vh];
        grid-auto-rows: 1fr;

        .card {
            @apply min-h-80 p-6 rounded-8;
            @apply min-h-80 p-6 rounded-16 shadow-lg;
            @apply bg-white;
        }
    }
}
