@tailwind base;
@tailwind components;
@tailwind utilities;

// @import "tailwindcss/base";
// @import "tailwindcss/components";
// @import "tailwindcss/utilities"; 

@import 'base.style';
@import 'layout.style';
@import 'page.style';