* {
    box-sizing: border-box;
}

body {
    @apply text-16;
    background-color: #E6E6E6;
}

svg {
    @apply inline;
}
